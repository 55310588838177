import {
  Form,
  TextInput,
  SelectInput,
  FileInput,
  FileField,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { AttachFile, Cancel, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import Button from "../buttons/button";
import CustomRichTextInput from "../inputs/custom-rich-text-input";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, hasValue, file } from "../../utils/schemas";

const Schema = Yup.object({
  client_files: Yup.array().min(1, "Selecione pelo menos um arquivo!").of(
    Yup.object().shape(
    {
      type_file: leastOneOption(),
      file_name: hasValue(),
      file: Yup.bool()
        .nullable()
        .when("type_file", { is: 1, then: () => file() }),
      html_string: Yup.bool()
        .nullable()
        .when("type_file", { is: 2, then: () => hasValue() })
    })
  )
});

function DialogClientAddFiles({ isEnterprise = true, isClient = true })
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleSubmit = async (values) =>
  {
    var url;

    if (isEnterprise)
    {
      if (isClient)
        url = `client/${record.id}/add/files`;
      else
        url = `client-prospection/${record.id}/add/files`;
    }
    else
    {
      if (isClient)
        url = `customer-client/${record.id}/add/files`;
      else
        url = `customer-client-prospection/${record.id}/add/files`;
    }

    const { error } = await action(url, 'POST', values);
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    refresh();
    toggle();
    notify('Arquivo(s) adicionado(s) com sucesso!', { type: "success" });
  };

  return (
    <>
      <Button onClick={handleClick} label="ARQUIVO">
        <AttachFile />
      </Button>

      <Dialog
        fullWidth
        open={on}
        onClose={handleCloseClick}
        aria-label="Novo(s) Arquivo(s)">
        <Form
          resolver={yupResolver(Schema)}
          onSubmit={handleSubmit}>
          <DialogTitle>
            Novo(s) Arquivo(s)
          </DialogTitle>
          <DialogContent>
            <ArrayInput label="Arquivo(s)" source="client_files">
              <SimpleFormIterator disableReordering fullWidth>
                <SelectInput source="type_file" label="Tipo de Arquivo" choices={[ { id: 1, name: 'Arquivo' }, { id: 2, name: 'Texto' } ]} fullWidth isRequired />
                <TextInput source="file_name" label="Nome" fullWidth isRequired />
                <FormDataConsumer>
                {
                  ({ formData, scopedFormData, ...rest }) => scopedFormData.type_file === 1 ?
                  <FileInput source="file" label="Arquivo" isRequired>
                    <FileField source="src" title="title" />
                  </FileInput>
                  : scopedFormData.type_file === 2 ?
                  <CustomRichTextInput source="html_string" label="Texto" fullWidth isRequired />
                  : null
                }
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </DialogContent>
          <DialogActions>
            <Button
              label="Cancelar"
              disabled={loading}
              color="error"
              onClick={handleCloseClick}>
              <Cancel />
            </Button>
            <Button
              label="Salvar"
              color="success"
              type="submit"
              disabled={loading}>
              <Save />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

export default DialogClientAddFiles;
